import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { APIResponse } from '../models/APIResponse';

@Injectable({
    providedIn: 'root',
})
export class GlobalProviderService {
    // public API_URL: string = 'http://98.81.245.135:3000/api';

    // public API_URL: string = 'http://98.81.245.135:3000/api';
    public API_URL: string = 'http://3.95.227.132:3000/api';

    constructor(private http: HttpClient) {}

    httpPost(apiEndPoint: string, postObj: Object, headers?: HttpHeaders) {
        return new Promise((resolve, reject) => {
            this.http
                .post(this.API_URL + apiEndPoint, postObj, { headers })
                .subscribe(
                    (res: APIResponse) => {
                        if (!res.isError && res.data != null) {
                            resolve(res.data);
                        } else {
                            reject(res.message);
                        }
                    },
                    (err) => {
                        console.log('post', err);
                        reject(err);
                    }
                );
        });
    }

    /**
     * Performs HTTP Put Request on given API End Point with given Put data
     * @param apiEndPoint API End Point to perform Put request on
     * @param postObj POST Data to be sent in request
     */
    httpPut(apiEndPoint: string, postObj: Object) {
        return new Promise((resolve, reject) => {
            this.http.put(this.API_URL + apiEndPoint, postObj).subscribe(
                (res: APIResponse) => {
                    if (!res.isError && res.data != null) {
                        resolve(res.data);
                    } else {
                        reject(res.message);
                    }
                },
                (err) => {
                    console.error(err);
                    reject(err);
                }
            );
        });
    }

    /**
     * Performs HTTP GET Request on given api endpoint
     * @param apiEndPoint API End-point to perform GET request on
     */
    httpGet(apiEndPoint: string) {
        return new Promise((resolve, reject) => {
            this.http.get(this.API_URL + apiEndPoint).subscribe(
                (res: APIResponse) => {
                    if (!res.isError && res.data != null) {
                        resolve(res.data);
                    } else {
                        reject(res.message);
                    }
                },
                (err) => {
                    console.error(err);
                    reject(err);
                }
            );
        });
    }

    /**
     * Performs HTTP GET Request on given api endpoint
     * @param apiEndPoint API End-point to perform GET request on
     */
    externalHttpGet(apiEndPoint: string) {
        return new Promise((resolve, reject) => {
            this.http.get(apiEndPoint).subscribe(
                (res: APIResponse) => {
                    resolve(res);
                },
                (err) => {
                    console.error(err);
                    reject(err);
                }
            );
        });
    }
}
